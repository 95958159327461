import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { AmpXImage } from "../../customs/ampImage";

const BlogImage = ({ data, width, cssclass, isAMP }) => {
  const queryData = useStaticQuery(graphql`
    query {
      NoImage: file(relativePath: { eq: "no-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const NoImage = queryData.NoImage;

  return (
    <>
      {data.blogimg && data?.blogimg?.length !== 0 ? (
        <>
          {data.blogimg && data?.blogimg[0].ext === ".svg" ? (
            <AmpXImage
              isAMP={isAMP}
              src={data?.blogimg[0]?.url}
              alt={data?.title}
              width={width && width}
              className={cssclass && cssclass}
            />
          ) : (
            <Img
              fluid={
                data.blogimg &&
                data?.blogimg[0]?.localFile?.childImageSharp?.fluid
              }
              alt={data?.title}
              width={width && width}
              className={cssclass && cssclass}
            />
          )}
        </>
      ) : (
        <Img
          fluid={NoImage?.childImageSharp?.fluid}
          alt={data?.title}
          width={width && width}
          className={cssclass && cssclass}
        />
      )}
    </>
  );
};

export default BlogImage;
